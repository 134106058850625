// oh geez
import "./src/styles.css";

// Material-UI was designed with the Roboto font in mind.
// The Roboto font will be automatically loaded by gatsby-theme-material-ui.

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// gatsby-browser.js and gatsby-ssr.js
import React from "react";

import { ThemeProvider } from "./src/components/ThemeContext";

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider>{element}</ThemeProvider>;
};
