import { createTheme } from "@mui/material/styles";

// Create the theme instances
const lightTheme = createTheme({
  palette: { mode: "light" },
});

const darkTheme = createTheme({
  palette: { mode: "dark" },
});

export { lightTheme, darkTheme };
